import React, { Component } from "react";

export default class PlateArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    /*
      { key: "A", value: "A", text: "الف" },
      { key: "P", value: "P", text: "پ" },
      { key: "TH", value: "TH", text: "ث" },
      { key: "SIN", value: "SIN", text: "س" },
      { key: "SH", value: "SH", text: "ش" },
      { key: "PUV", value: "PUV", text: "ع" }, //PuV (Public Vehicle)
      { key: "F", value: "F", text: "ف" },
      { key: "GH", value: "GH", text: "ق" },
      { key: "K", value: "K", text: "ک" },
      { key: "G", value: "G", text: "گ" },
      { key: "DIP", value: "DIP", text: "D" },
      { key: "SER", value: "SER", text: "S" },
       */
  }
  tashkhisOstan = (num) => {
    const ostan = [
      { number: "12", province: "Khorasan" },
      { number: "32", province: "Khorasan" },
      { number: "42", province: "Khorasan" },
      { number: "36", province: "Khorasan" },
      { number: "74", province: "Khorasan" },
      { number: "13", province: "Isfahan" },
      { number: "23", province: "Isfahan" },
      { number: "43", province: "Isfahan" },
      { number: "53", province: "Isfahan" },
      { number: "67", province: "Isfahan" },
      { number: "93", province: "Fars" },
      { number: "83", province: "Fars" },
      { number: "73", province: "Fars" },
      { number: "63", province: "Fars" },
      { number: "30", province: "Alborz" },
      { number: "38", province: "Alborz" },
      { number: "21", province: "Alborz" },
      { number: "78", province: "Alborz" },
      { number: "68", province: "Alborz" },
      { number: "34", province: "Khuzestan" },
      { number: "24", province: "Khuzestan" },
      { number: "14", province: "Khuzestan" },
      { number: "15", province: "AzarbayjanSharghi" },
      { number: "25", province: "AzarbayjanSharghi" },
      { number: "35", province: "AzarbayjanSharghi" },
      { number: "91", province: "Ardebil" },
    ];
    const temp = ostan.findIndex((el) => el.number === num);
    if (temp !== -1) return ostan[temp].province;
    return false;
  };
  getData = () => {
    const data = [
      {
        name: "PWD",
        title: "پلاک ویژه معلولین و جانبازان",
        content: (
          <>
            <p>
              این پلاک ویژهٔ معلولین و جانبازان طراحی شده‌است. دلیل طراحی این
              پلاک این بوده‌است که در صورت پارک در محل پارک ویژهٔ معلولین و
              جانبازان قابل تشخیص باشند و همچنین برای دادن سهمیه بنزین و درمان
              نیز ساماندهی شوند. این پلاک کاملاً شبیه به پلاک ملی شخصی صادر
              می‌شود و تنها تفاوتش در این است که در آن به جای حروف از نماد ویژه
              معلولین و جانبازان استفاده شده‌است. این پلاک در برخی سامانه‌ها
              مانند تاکسی‌های اینترنتی یا سامانه‌های مربوط به عوارض خودرو - که
              از نماد استفاده نمی‌کنند- به صورت حرف «ژ» نمایش داده می‌شود. در
              این پلاک سرشماره ۱۱ تا ۴۹ برای افراد جانباز و سرشماره ۵۱ تا ۹۹
              برای افراد معلول در نظر گرفته شده است.
            </p>
            <img alt="PWD" src="./img/PWD.png" />
          </>
        ),
      },
      {
        name: "G",
        title: "پلاک‌های گذرموقت",
        content: (
          <>
            <p>
              این پلاک برای خودروهایی طراحی شده‌است که از خارج از کشور و یا از
              مناطق آزاد تجاری وارد شهرها می‌شوند و برای آن‌ها مهلت محدودی مشخص
              شده‌است. در این پلاک از حرف گ استفاده می‌شود و یک تاریخ نیز در
              قسمت پایینی کادر مربع‌شکل به پلاک اضافه شده‌است.
            </p>
            <img alt="G" src="./img/GZ.jpg" />
          </>
        ),
      },
      {
        name: "TAXI",
        title: "تاکسی",
        content: (
          <>
            <p>
              پلاک ملی تاکسی‌ها فقط برای ناوگان تاکسی رانی در ایران صادر می‌شود.
              شکل پلاک تاکسی‌ها شبیه به پلاک خودروهای شخصی است؛ با این تفاوت که
              رنگ زمینهٔ آن زرد و حرف الفبای آن ت است که در بالای آن
              کلمهٔ TAXI (تاکسی) به لاتین نوشته شده‌است. کد منطقهٔ صدور پلاک در
              واقع محل انجام وظیفه تاکسی را مشخص می‌کند. اعداد و حروف این پلاک
              مشکی بوده و بر روی یک پس‌زمینهٔ زردرنگ چاپ می‌شود. این نوع پلاک به
              صورت کد منطقه‌ایِ مرکز استان‌ها (غیر از استان تهران) صادر می‌شود.
            </p>
            <img alt="taxi" src="./img/TAXI.png" />
          </>
        ),
      },
      {
        name: "PUV",
        title: "عمومی",
        content: (
          <>
            <p>
              پلاک ملی خودروهای عمومی، پلاکی است که به خودروهای حمل‌ونقل
              عمومی مانند تریلی‌ها، وانت‌بارها، اتوبوس و مینی‌بوس‌ها و … تعلق
              دارد. اعداد و حروف این پلاک مشکی است و بر روی یک پس‌زمینهٔ زرد رنگ
              چاپ می‌شود. در این نوع پلاک حرف ع به معنای عمومی چاپ می‌شوداین نوع
              پلاک به صورت کد منطقه ای مرکز استان (به غیر از تهران) صادر میشود
            </p>
            <img alt="Public" src="./img/Public.png" />
          </>
        ),
      },
      {
        name: "K",
        title: "کشاورزی",
        content: (
          <p>
            پلاک ملی ماشین آلات کشاورزی، پلاکی است که به ماشین
            آلات کشاورزی مانند تراکتور، کمباین و … تعلق دارد. اعداد و حروف این
            پلاک مشکی است و بر روی یک پس‌زمینهٔ زرد رنگ چاپ می‌شود. در این نوع
            پلاک حرف ک به معنای کشاورزی چاپ می‌شوداین نوع پلاک به صورت کد منطقه
            ای مرکز استان (به غیر از تهران) صادر میشود
          </p>
        ),
      },
      {
        name: "A",
        title: "دولتی",
        content: (
          <>
            <p>
              این پلاک مخصوص خودروهای دولتی اعم
              از سواری، اتوبوس، مینی‌بوس، کامیون و… است. این پلاک به شکل مستطیل
              است و از چپ به راست خوانده می‌شود و از چپ به راست شامل یک عدد
              دورقمی، حرف الفـ، یک عدد سه‌رقمی و یک عدد دورقمی در کادر مربع‌شکل
              است. اعداد و حروف این پلاک سفیدرنگ است که بر روی یک پس‌زمینهٔ
              قرمزرنگ چاپ می‌شود.
            </p>
            <img alt="Dowlati" src="./img/DW.png" />
          </>
        ),
      },
      {
        name: "F", //پ ش ف ز ث
        title: "نظامی",
        content: (
          <>
            <p>
              این پلاک ویژه خودروهای نیروی انتظامی (پ)، سپاه (ث)، وزارت دفاع
              (ز)، ارتش (ش) و ستاد کل فرماندهی نیروهای مسلح (ف) است. این پلاک به
              شکل مستطیل است و از چپ به راست خوانده می‌شود که شامل یک عدد
              دورقمی، یک عدد سه‌رقمی، و در آخر، یک عدد دورقمی در یک کادر
              مربع‌شکل است که محل صدور پلاک را نشان می‌دهد.
            </p>
            <img alt="Nezami" src="./img/N1.png" />
            <img alt="Nezami" src="./img/N2.png" />
            <img alt="Nezami" src="./img/N3.png" />
            <img alt="Nezami" src="./img/N4.png" />
            <img alt="Nezami" src="./img/N5.png" />
          </>
        ),
      },
      {
        name: "TARIKHI",
        title: "پلاک خودروهای تاریخی",
        content: (
          <>
            <p>
              پلاک خودروهای تاریخی ایران پلاکی است که به خودروهای قدیمی که دارای
              ویژگی‌های زیر باشند: خودروهای نفیس: خودروهایی که بیش از ۵۰ سال از
              تولید آن گذشته باشد. خودروهای قدیمی: خودروهایی که بیش از ۲۵ سال از
              تولید آن گذشته باشد و از لحاظ طراحی و مهندسی ساخت یا کاربردی
              بی‌نظیر باشد. خودروهای ویژه: خودروهایی که دارای بعد فرهنگی باشد.
              این پلاک به شکل مستطیل است و برخلاف سایر پلاک‌های مورد استفاده،
              اندازهٔ آن به صورت استاندارد آمریکایی (دارای ۱۲ اینچ طول و ۶ اینچ
              عرض) است و به سه قسمت مستطیلی تقسیم می‌شود که در مستطیل سمت راست
              بالا عبارت تاریخی ذکر شده، در سمت راست پایین شمارهٔ پلاک قید شده و
              در سمت چپ پلاک در یک نوار آبی‌رنگ پرچم ایران در بالا که زیر آن
              عبارت I.R. IRAN نوشته شده و در قسمت پایین همین نوار عکسی از مکانی
              تاریخی زده شده‌است.
            </p>
            <img alt="Tarikhi" src="./img/Tarikhi.png" />
          </>
        ),
      },
      {
        name: "S", // S, d
        title: "پلاک سیاسی",
        content: (
          <>
            <p>
              پلاک سیاسی مخصوص خودروهای سفارت‌ها است و فقط کارکنان شاغل در سفارت
              می‌توانند از این خودروها استفاده کنند. صاحبان این خودروها بدون
              عوارض واردات خودرو می‌توانند خودرو خود را وارد کنند. حرف میانی در
              این پلاک‌ها به دو صورت D (برای دیپلمات‌ها) و S (برای خدمات
              سفارت‌خانه‌ها) است. در رابطه با این خودروها باید کمال دقت را داشته
              باشید، چرا که طبق قوانین دیپلماتیک، این خودروها از حفاظت خاصی
              برخوردار هستند و در صورت تصادف با آن ها باید مسیر سختی را
              بگذرانید.
            </p>
            <img alt="SD" src="./img/S1.png" />
            <img alt="SD" src="./img/S2.png" />
            <img alt="SD" src="./img/S3.png" />
          </>
        ),
      },
      {
        name: "Alborz",
        title: "استان‌ البرز (۲۱، ۳۰، ۳۸، ۶۸، ۷۸)",
        content: (
          <p>
            با اتمام کد پلاک شهرستان ساوجبلاغ ، طالقان و نظرآباد، کد پلاک کل
            استان البرز با هم واگذار می‌شود.
          </p>
        ),
      },
      {
        name: "Khorasan",
        title: "استان‌ خراسان رضوی (۱۲، ۳۲، ۴۲، ۳۶، ۷۴)",
        content: (
          <ul>
            <li>
              ۴۲ م - کد مشترک گناباد و رشتخوار (تا سرشماره ۳۶ اختصاصی رشتخوار و
              از سرشماره ۳۷ مشترک با گناباد)
            </li>
            <li>
              ۴۲ ی - کد مشترک کلات و تربت حیدریه (تا سرشماره ۲۱ اختصاصی کلات و
              از سرشماره ۲۲ مشترک با تربت حیدریه)
            </li>
          </ul>
        ),
      },
      {
        name: "Isfahan",
        title: "استان اصفهان (۱۳، ۲۳، ۴۳، ۵۳، ۶۷)",
        content: (
          <ul>
            <li>
              ۶۷ ص - کد مشترک لنجان و فلاورجان (تا سرشماره ۲۹ اختصاصی لنجان و از
              سرشماره ۳۱ مشترک با فلاورجان)
            </li>
            <li>
              ۴۳ ص - کد مشترک تیران و کرون و نجف آباد (تا سرشماره ۵۵ اختصاصی
              تیران و کرون و از سرشماره ۵۶ مشترک با نجف آباد)
            </li>
          </ul>
        ),
      },
      {
        name: "Fars",
        title: "استان فارس (۶۳، ۷۳، ۸۳، ۹۳)",
        content: (
          <ul>
            <li>
              ۹۳ ی - کد مشترک شیراز و کازرون (تا سرشماره ۱۶ اختصاصی کازرون / از
              سرشماره ۱۷ مشترک با شیراز)
            </li>
            <li>
              ۸۳ س - کد مشترک داراب و مُهر (تا سرشماره ۳۸ اختصاصی مُهر/ از
              سرشماره ۳۹ مشترک با داراب)
            </li>
            <li>
              ۹۳ و - کد مشترک شیراز و مرودشت و کازرون (تا سرشماره ۳۹ اختصاصی
              مرودشت / از سرشماره ۴۱ مشترک با شیراز و کازرون)
            </li>
            <li>
              با اتمام پلاک شهرستان هایی که پلاک آنها رو به اتمام است از پلاک
              های فک شده همان شهرستان استفاده خواهد شد.
            </li>
          </ul>
        ),
      },
      {
        name: "Khuzestan",
        title: "استان خوزستان (۳۴٬۲۴٬۱۴)",
        content: (
          <p>
            ۲۴ ه‍ - کد مشترک شادگان و ماهشهر (تا سرشماره ۵۳ اختصاصی شادگان و از
            سرشماره ۵۴ مشترک با ماهشهر)
          </p>
        ),
      },
      {
        name: "AzarbayjanSharghi",
        title: "استان آذربایجان شرقی (۱۵، ۲۵، ۳۵)",
        content: (
          <ul>
            <li>
              ۳۵ د - کد مشترک عجب شیر و بناب (تا سرشماره ۴۶ اختصاصی عجب شیر و از
              سرشماره ۴۷ مشترک با بناب)
            </li>
            <li>
              ۳۵ س - کد مشترک چارویماق و تبریز (تا سر شماره ۲۲ اختصاصی چارویماق
              واز سرشماره ۲۳ مشترک با تبریز)
            </li>
          </ul>
        ),
      },
      {
        name: "Ardebil",
        title: "استان اردبیل (۹۱)",
        content: (
          <p>
            بعد از اتمام حرف ن، حرف م به شهرهای دارای پلاک اتمام یافته اختصاص می
            یابد.
          </p>
        ),
      },
    ];
    /*
    {
      name: "",
      title: "شهرستان‌ها و کد پلاک",
      content: (
        <div>
          به علت محدودیت در واگذاری کدهای جدید، در آینده و به مرور پلاک‌ها از
          تفکیک شهرستان خارج شده و به صورت استانی واگذار خواهند شد.
        </div>
      ),
    },
    */
    const number = this.props.number;
    const letter = this.props.letter;
    let myletter = false;
    const avail = [
      "PWD",
      "G",
      "TAXI",
      "PUV",
      "K",
      "A",
      "F", //پ ش ف ز ث
      "S", // S, d
      "Alborz",
      "Khorasan",
      "Isfahan",
      "Fars",
      "Khuzestan",
      "AzarbayjanSharghi",
      "Ardebil",
    ];
    const temp = avail.findIndex((el) => letter === el);
    if (temp !== -1) {
      const t = data.find((el) => el.name === avail[temp]);
      return t;
    } else {
      if (
        letter === "TH" ||
        letter === "F" ||
        letter === "SH" ||
        letter === "P" ||
        letter === "Z"
      )
        myletter = "F";
      else if (letter === "SER" || letter === "DIP") myletter = "S";
      else {
        /// this is ostan
        myletter = this.tashkhisOstan(number);
      }
      if (myletter) {
        const t = data.find((el) => el.name === myletter);
        return t;
      }
    }
    return false;
  };
  render() {
    const content = this.getData();
    if (content)
      return (
        <div className="plate-article">
          <h4>{content.title}</h4>
          <div>{content.content}</div>
        </div>
      );
    return <div className="plate-article"></div>;
  }
}
